import { Binder } from '@canalplus/one-navigation';
import type { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import classNames from 'classnames';
import type { JSX } from 'react';
import IconTvCheck from '../../../assets/svg/tvCheck.svg';
import IconTvCheckTv from '../../../assets/svg/tvCheckTv.svg';
import { MIDDLEWARE_LIVE_GRID_PERSO } from '../../../helpers/oneNavigation/middleware';
import I18n from '../../../lang';
import LiveGridEditFavoritesChannels, {
  LiveGridFavoritesButtonTypes,
} from '../LiveGridEditFavoritesChannels/LiveGridEditFavoritesChannels';
import styles from './LiveGridPerso.css';

export type LiveGridPersoProps = {
  isEmptyState?: boolean;
  isTvDevice?: boolean;
  liveGridPageTracking?: Tracking;
  refetchLiveGridRequest?: () => void;
};

function LiveGridPerso({
  isEmptyState = false,
  isTvDevice,
  liveGridPageTracking,
  refetchLiveGridRequest = () => {},
}: LiveGridPersoProps): JSX.Element {
  const { t } = I18n.useTranslation();

  return (
    <div className={classNames(styles.LiveGridPerso, 'LiveGridPerso')}>
      {isEmptyState && (
        <div className={styles.LiveGridPerso__favoritesWrap}>
          {!isTvDevice ? (
            <div className={styles.LiveGridPerso__favoritesIconWrap}>
              <IconTvCheck data-testid="iconTvCheck" />
            </div>
          ) : (
            <IconTvCheckTv data-testid="iconTvCheckTv" />
          )}
          <h2 className={styles.LiveGridPerso__favoritesHeading}>
            {t('LiveGrid.favorites.emptyStateHeading')}
          </h2>
          <p className={styles.LiveGridPerso__text}>
            {t('LiveGrid.favorites.emptyStateText')}
          </p>
          <Binder middleware={MIDDLEWARE_LIVE_GRID_PERSO}>
            <LiveGridEditFavoritesChannels
              isTvDevice={isTvDevice}
              liveGridPageTracking={liveGridPageTracking}
              refetchLiveGridRequest={refetchLiveGridRequest}
              type={LiveGridFavoritesButtonTypes.EMPTY_STATE}
            />
          </Binder>
        </div>
      )}
      {!isEmptyState && (
        <div className={classNames(styles.LiveGridPerso__editButtonWrap)}>
          <Binder middleware={MIDDLEWARE_LIVE_GRID_PERSO}>
            <LiveGridEditFavoritesChannels
              isTvDevice={isTvDevice}
              liveGridPageTracking={liveGridPageTracking}
              refetchLiveGridRequest={refetchLiveGridRequest}
            />
          </Binder>
        </div>
      )}
    </div>
  );
}

export default LiveGridPerso;
